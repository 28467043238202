import React from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import styled from 'styled-components';

const AdminDashboard = () => {
    const handleGenerateBrackets = (tournamentId) => {
        axios.post(`https://api.senc.in/api/tournaments/${tournamentId}/generate-brackets`)
            .then(response => {
                alert('Brackets generated successfully');
            })
            .catch(error => {
                console.error('Error generating brackets:', error);
                alert('Error generating brackets');
            });
    };

    return (
        <DashboardContainer>
            <Title>Admin Dashboard</Title>
            <NavList>
                <NavItem><StyledLink to="/ManageUsers">Manage Users</StyledLink></NavItem>
                <NavItem><StyledLink to="/ManageTournaments">Manage Tournaments</StyledLink></NavItem>
                <NavItem><StyledLink to="/admin/teams">Manage Teams</StyledLink></NavItem>
                <NavItem><StyledLink to="/admin/brackets">Manage Brackets</StyledLink></NavItem>
            </NavList>
            <GenerateButton onClick={() => handleGenerateBrackets(tournamentId)}>Generate Brackets</GenerateButton>
        </DashboardContainer>
    );
};

export default AdminDashboard;

// Styled Components
const DashboardContainer = styled.div`
    padding: 2rem;
    background-color: #f9f9f9;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled.h1`
    color: #333;
    margin-bottom: 2rem;
`;

const NavList = styled.ul`
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
`;

const NavItem = styled.li`
    margin: 0.5rem 0;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #007bff;
    font-size: 1.2rem;
    &:hover {
        color: #0056b3;
    }
`;

const GenerateButton = styled.button`
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: #0056b3;
    }
`;

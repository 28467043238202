import React, { useEffect, useState } from 'react';
import { Bracket, BracketGame } from 'react-tournament-bracket';
import { Box, Text, VStack, Select, Button, useToast } from '@chakra-ui/react';

function ViewBrackets() {
    const [matches, setMatches] = useState([]);
    const [teams, setTeams] = useState({});
    const [tournaments, setTournaments] = useState([]);
    const [selectedTournament, setSelectedTournament] = useState('');
    const [winnerIds, setWinnerIds] = useState({});
    const toast = useToast();

    // Fetch tournaments
    useEffect(() => {
        const fetchTournaments = async () => {
            try {
                const response = await fetch('http://localhost:3001/api/tournaments');
                const data = await response.json();
                setTournaments(data);
            } catch (error) {
                console.error('Error fetching tournaments:', error);
            }
        };

        fetchTournaments();
    }, []);

    // Fetch brackets and teams when a tournament is selected
    useEffect(() => {
        if (!selectedTournament) return;

        const fetchBracketsAndTeams = async () => {
            try {
                const response = await fetch(`http://localhost:3001/api/tournaments/${selectedTournament}/brackets`);
                const brackets = await response.json();

                const teamResponse = await fetch(`http://localhost:3001/api/tournaments/${selectedTournament}/teams`);
                const teams = await teamResponse.json();

                // Map team IDs to names
                const teamMap = teams.reduce((acc, team) => {
                    acc[team.id] = team.name;
                    return acc;
                }, {});

                setTeams(teamMap);

                // Convert the API response to the format required by the bracket component
                const formattedMatches = brackets.map(match => ({
                    id: match.matchNumber,
                    name: `Match ${match.matchNumber}`,
                    scheduled: false,
                    sides: {
                        home: {
                            team: { id: match.team1Id, name: teamMap[match.team1Id] || `Team ${match.team1Id}` },
                            score: { score: match.team1Score || 0 },
                        },
                        visitor: {
                            team: { id: match.team2Id, name: teamMap[match.team2Id] || `Team ${match.team2Id}` },
                            score: { score: match.team2Score || 0 },
                        },
                    },
                }));

                setMatches(formattedMatches);
            } catch (error) {
                console.error('Error fetching brackets or teams:', error);
            }
        };

        fetchBracketsAndTeams();
    }, [selectedTournament]);

    const handleWinnerChange = (matchId, winnerId) => {
        setWinnerIds(prev => ({ ...prev, [matchId]: winnerId }));
    };

    const updateWinner = async (matchId) => {
        const winnerId = winnerIds[matchId];
        if (!winnerId) {
            toast({ title: 'Please select a winner.', status: 'error', duration: 3000 });
            return;
        }

        try {
            const response = await fetch('http://localhost:3001/api/brackets/updates', { // Ensure the correct endpoint
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    matchId,
                    winnerId,
                    tournamentId: selectedTournament // Send the selected tournament ID
                }),
            });

            const result = await response.json();
            if (response.ok) {
                toast({ title: result.message, status: 'success', duration: 3000 });
                await fetchBracketsAndTeams(); // Refresh the bracket data to reflect the changes
            } else {
                toast({ title: result.message, status: 'error', duration: 3000 });
            }
        } catch (error) {
            console.error('Error updating bracket:', error);
            toast({ title: 'Error updating bracket.', status: 'error', duration: 3000 });
        }
    };

    return (
        <Box className="tournament-bracket-container" p={4} bg="gray.800" color="white" minH="100vh" pt="6rem">
            <Select
                placeholder="Select Tournament"
                onChange={(e) => setSelectedTournament(e.target.value)}
                value={selectedTournament}
                mb={4}
                bg="gray.700"
                color="white"
            >
                {tournaments.map(tournament => (
                    <option key={tournament.id} value={tournament.id}>
                        {tournament.name}
                    </option>
                ))}
            </Select>

            {matches.length > 0 ? (
                <VStack className="bracket-wrapper" spacing={4} align="start">
                    {matches.map((match) => (
                        <Box key={match.id} className="bracket-item" p={4} borderWidth="1px" borderRadius="lg" bg="gray.700">
                            <Bracket
                                game={match}
                                GameComponent={BracketGame}
                            />
                            <Select
                                placeholder="Select Winner"
                                mt={2}
                                onChange={(e) => handleWinnerChange(match.id, e.target.value)}
                                value={winnerIds[match.id] || ''}
                                bg="gray.600"
                                color="white"
                            >
                                <option value={match.sides.home.team.id}>{match.sides.home.team.name}</option>
                                <option value={match.sides.visitor.team.id}>{match.sides.visitor.team.name}</option>
                            </Select>
                            <Button
                                onClick={() => updateWinner(match.id)}
                                mt={2}
                                colorScheme="teal"
                                size="sm"
                            >
                                Update Winner
                            </Button>
                        </Box>
                    ))}
                </VStack>
            ) : (
                <Text className="no-brackets-message">No brackets available</Text>
            )}
        </Box>
    );
}

export default ViewBrackets;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './pages/NavBar';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import {Dashboard, ManageProfile} from './pages/Dashboard';
import AdminPanel from './pages/AdminDashboard'; // Import AdminPanel
import ProtectedRoute from './pages/ProtectedRoute';
import AdminUsers from './pages/ManageUsers';
import AdminTournaments from './pages/admin/ManageTournaments';
import Footer from './components/Footer'; // Import Footer
import PrivacyPolicy from './pages/PrivacyPolicy'; // Import PrivacyPolicy
import ContactUs from './pages/ContactUs'; // Import ContactUs
import TermsAndConditions from './pages/TermsAndConditions';
import Changelog from './pages/Changelog'; // Import Changelog
import Squad from './pages/Squad';
import ViewTournaments from './pages/ViewTournaments';
import FusionPrime from "./pages/organizers/FusionPrime";
import BorokBattle  from "./pages/organizers/BorokBattle";

import MicXLengvei from "./pages/organizers/MicXLengvei";
import ViewPrime from "./pages/organizers/ViewPrime";
import ViewBorok from "./pages/organizers/ViewBorok";
import ViewLengvei from "./pages/organizers/ViewLengvei";
import BorokBattleAdmin from "./pages/organizers/BorokBattleAdmin";
import ViewBrackets from "./pages/ViewBrackets";
import CrownChampion from "./pages/organizers/CrownChampion";
import ZOKHAWNGLUNGRUN from "./pages/organizers/ZOKHAWNGLUNGRUN";
import ViewZo from "./pages/organizers/ViewZo";
import UpdateBrackets from "./pages/admin/UpdateBrackets";
import ManageTournament from "./pages/ManageTournament";
import ViewTeams from "./pages/ViewTeams";
import UpdateBracketsUser from "./pages/UpdateBracketsUser";


import CreateTournament from "./pages/CreateTournament";
import MLOS from "./pages/organizers/MLOS";
import MLMS from "./pages/organizers/MLMS";
import GirlsTourny from "./pages/organizers/GirlsTorny";





const App = () => {

    return (
        <>
            <NavBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/ManageUsers" element={<AdminUsers />} />
                <Route path="/ManageTournamentsAdmin" element={<AdminTournaments />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/changelog" element={<Changelog />} />
                <Route path="/squad" element={<Squad />} />
                <Route path="/view-tournaments" element={<ViewTournaments />} />
                <Route path="/fusion-prime" element={<FusionPrime />} />
                <Route path="/borok-battle" element={<BorokBattle />} />

                <Route path="/mic-x-lengvei" element={<MicXLengvei />} />
                <Route path="/view-crown" element={<ViewPrime />} />
                <Route path="/view-borok" element={<ViewBorok />} />
                <Route path="/view-lengvei" element={<ViewLengvei />} /> 
                <Route path="/borok-admin" element={<BorokBattleAdmin />} />
                <Route path="/view-brackets/:tournamentId" element={<ViewBrackets />} />
                 <Route path="/manage-profile" element={<ManageProfile />} />
                 <Route path="/crown-champion" element={<CrownChampion />} />
                 <Route path="/ZO-KHAWNGLUNGRUN" element={<ZOKHAWNGLUNGRUN />} />
                 <Route path="view-zo" element={<ViewZo />} />
                 <Route path="/manage-tournament/:id" element={<ManageTournament />} />
                <Route path="/create-tournament" element={<CreateTournament />} />
<Route path="/view-teams/:id" element={<ViewTeams />} />
                <Route path="/update-brackets-user/:id" element={<UpdateBracketsUser />} />
                 <Route path="/mlos" element={<MLOS />} />
                  <Route path="/mlms" element={<MLMS />} />
                  <Route path="/girlstourny" element={<GirlsTourny />} />
                 
               






                <Route
                    path="/admin"
                    element={
                        <ProtectedRoute isAdmin={true}>
                            <AdminPanel />
                        </ProtectedRoute>
                    }
                />
            </Routes>
            
        </>

    );
};


export default App;

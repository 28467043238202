// src/pages/PrivacyPolicy.js
import React from 'react';
import styled from 'styled-components';

const PrivacyPolicy = () => {
    const lastUpdated = new Date().toLocaleDateString();

    return (
        <PageContainer>
            <Title>Privacy Policy</Title>
            <Paragraph>
                <strong>SENC PRIVACY POLICY</strong><br />
                Last Updated: {lastUpdated}
            </Paragraph>
            <Paragraph>
                SENC values your privacy. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website and services.
            </Paragraph>
            <Section>
                <SectionTitle>1. Information We Collect</SectionTitle>
                <Paragraph>
                    <strong>1.1 Personal Information:</strong> We collect personal information that you provide when creating an account, such as your name, email address, phone number, and government-issued ID (for core members).
                </Paragraph>
                <Paragraph>
                    <strong>1.2 Automatically Collected Information:</strong> We may collect certain data automatically, such as your IP address, browser type, operating system, and browsing history through cookies or other tracking technologies.
                </Paragraph>
                <Paragraph>
                    <strong>1.3 Third-Party Information:</strong> We may collect information from third parties, such as payment processors, to process transactions.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>2. Use of Information</SectionTitle>
                <Paragraph>
                    <strong>2.1 Service Delivery:</strong> We use your personal information to provide and improve our services, manage your account, and communicate with you.
                </Paragraph>
                <Paragraph>
                    <strong>2.2 Marketing:</strong> With your consent, we may send you promotional materials related to our services. You can opt out at any time.
                </Paragraph>
                <Paragraph>
                    <strong>2.3 Legal Compliance:</strong> We may use or disclose your personal information to comply with applicable laws or in response to lawful requests.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>3. Sharing of Information</SectionTitle>
                <Paragraph>
                    <strong>3.1 Third-Party Service Providers:</strong> We may share your information with third-party service providers who help us operate our services, such as hosting providers, payment processors, and analytics tools.
                </Paragraph>
                <Paragraph>
                    <strong>3.2 Legal Requirements:</strong> We may disclose your information when required by law or to protect the rights, property, or safety of SENC, our users, or others.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>4. Data Retention</SectionTitle>
                <Paragraph>
                    We retain your personal information for as long as necessary to provide our services, comply with legal obligations, and resolve disputes.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>5. Security</SectionTitle>
                <Paragraph>
                    SENC takes reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no internet transmission is completely secure, and we cannot guarantee absolute security.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>6. Your Rights</SectionTitle>
                <Paragraph>
                    <strong>6.1 Access and Correction:</strong> You have the right to access and correct your personal information.
                </Paragraph>
                <Paragraph>
                    <strong>6.2 Deletion:</strong> You may request the deletion of your personal information, subject to certain legal exceptions.
                </Paragraph>
                <Paragraph>
                    <strong>6.3 Data Portability:</strong> You may request a copy of your personal data in a commonly used format.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>7. Cookies</SectionTitle>
                <Paragraph>
                    We use cookies and similar technologies to improve user experience, track website performance, and gather analytics data. You can manage your cookie preferences through your browser settings.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>8. Children's Privacy</SectionTitle>
                <Paragraph>
                    Our services are not intended for children under the age of 18. We do not knowingly collect personal information from children under 18. If we discover that we have inadvertently collected such data, we will take steps to delete it.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>9. Changes to This Policy</SectionTitle>
                <Paragraph>
                    SENC reserves the right to update this Privacy Policy. We will notify you of significant changes by posting a notice on our website or via email.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>10. Governing Law</SectionTitle>
                <Paragraph>
                    This Privacy Policy shall be governed by the laws of India. Any disputes related to this policy will be subject to the exclusive jurisdiction of the courts in Indian Jurisdiction.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>11. Contact Information</SectionTitle>
                <Paragraph>
                    If you have any questions or concerns regarding this Privacy Policy, please contact us at adh@senc.in.
                </Paragraph>
            </Section>
        </PageContainer>
    );
};

const PageContainer = styled.div`
    padding: 2rem;
`;

const Title = styled.h2`
    margin-bottom: 1rem;
`;

const Section = styled.section`
    margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h3`
    margin-bottom: 0.5rem;
`;

const Paragraph = styled.p`
    margin-bottom: 1rem;
`;

export default PrivacyPolicy;

import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet, Link as RouterLink } from 'react-router-dom';
import { Box, Button, Heading, Text, VStack, Avatar, Input, FormControl, FormLabel, Image, Flex, useColorModeValue, Icon, Divider, Badge, SimpleGrid } from '@chakra-ui/react';
import { FaTrophy, FaUserCog, FaUsers, FaGift, FaPlusCircle, FaClipboardList } from 'react-icons/fa';
import axios from 'axios';

const Dashboard = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState({ name: '', email: '', avatar: '', role: '', id: '' });
    const [showSidebar, setShowSidebar] = useState(true);
    const [showManageProfile, setShowManageProfile] = useState(false);
    const [showRewards, setShowRewards] = useState(false);
    const [showManageTournaments, setShowManageTournaments] = useState(false);
    const [createdTournaments, setCreatedTournaments] = useState([]);

    const bgColor = useColorModeValue('gray.100', 'gray.900');
    const sidebarBgColor = useColorModeValue('blue.600', 'blue.900');
    const textColor = useColorModeValue('gray.800', 'white');

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        const userRole = sessionStorage.getItem('role');
        const userId = sessionStorage.getItem('userId');
        if (!token) {
            navigate('/login');
        } else {
            setUser({ role: userRole, id: userId });
            fetchCreatedTournaments(userId);
        }
    }, [navigate]);

    const fetchCreatedTournaments = async (userId) => {
        try {
            const response = await axios.get(`https://api.senc.in/api/tournaments/created-by/${userId}`);
            setCreatedTournaments(response.data);
        } catch (error) {
            console.error('Error fetching created tournaments:', error);
        }
    };

    const handleManageProfile = () => {
        setShowManageProfile(true);
        setShowRewards(false);
        setShowManageTournaments(false);
    };

    const handleViewTournaments = () => {
        navigate('/view-tournaments');
    };

    const handleCreateSquad = () => {
        navigate('#');
    };

    const handleRewards = () => {
        setShowRewards(true);
        setShowManageProfile(false);
        setShowManageTournaments(false);
    };

    const handleManageTournaments = () => {
        setShowManageTournaments(true);
        setShowManageProfile(false);
        setShowRewards(false);
    };

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const handleCreateTournament = () => {
        navigate('/create-tournament');
    };

    return (
        <Flex minHeight="100vh">
            {showSidebar && (
                <Box
                    width="250px"
                    backgroundColor={sidebarBgColor}
                    color="white"
                    padding="1rem"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    paddingTop="8rem"
                    position="fixed"
                    height="100vh"
                    overflowY="auto"
                    zIndex="1"
                >
                    <Avatar size="xl" name={user.name} src={user.avatar} marginBottom="1rem" />
                    <Text fontSize="xl" fontWeight="bold" marginBottom="2rem">{user.name}</Text>
                    <VStack spacing="1rem" width="100%">
                        <Button leftIcon={<Icon as={FaTrophy} />} as={RouterLink} to="/view-tournaments" colorScheme="whiteAlpha" variant="solid" width="100%">
                            View Tournaments
                        </Button>
                        {user.role === 'admin' ||  user.role === 'manager'  && (
                            <>
                                <Button leftIcon={<Icon as={FaPlusCircle} />} onClick={handleCreateTournament} colorScheme="whiteAlpha" variant="solid" width="100%">
                                    Create Tournament
                                </Button>
                                <Button leftIcon={<Icon as={FaClipboardList} />} onClick={handleManageTournaments} colorScheme="whiteAlpha" variant="solid" width="100%">
                                    Manage Tournaments
                                </Button>
                            </>
                        )}
                        <Button leftIcon={<Icon as={FaUserCog} />} onClick={handleManageProfile} colorScheme="whiteAlpha" variant="solid" width="100%">
                            Manage Profile
                        </Button>
                        <Button leftIcon={<Icon as={FaUsers} />} onClick={handleCreateSquad} colorScheme="whiteAlpha" variant="solid" width="100%">
                            Create Squad
                        </Button>
                        <Button leftIcon={<Icon as={FaGift} />} onClick={handleRewards} colorScheme="whiteAlpha" variant="solid" width="100%">
                            Rewards
                        </Button>
                    </VStack>
                </Box>
            )}
            <Box
                flex="1"
                padding="2rem"
                paddingTop="8rem"
                backgroundColor={bgColor}
                color={textColor}
                marginLeft={showSidebar ? "250px" : "0"}
                transition="margin-left 0.3s"
            >
                <Button
                    onClick={toggleSidebar}
                    colorScheme="blue"
                    position="fixed"
                    top="1rem"
                    left={showSidebar ? "260px" : "1rem"}
                    zIndex="2"
                >
                    {showSidebar ? 'Hide Sidebar' : 'Show Sidebar'}
                </Button>
                <Heading as="h2" marginBottom="1rem" size="2xl">
                    Welcome to Your Dashboard
                </Heading>
                <Text marginBottom="2rem" fontSize="lg">
                    Here you can manage your profile, view tournaments, create teams, and more.
                </Text>
                {showManageProfile ? <ManageProfile /> :
                    showRewards ? <Rewards user={user} createdTournaments={createdTournaments} navigate={navigate} /> :
                        showManageTournaments ? <ManageTournaments user={user} createdTournaments={createdTournaments} navigate={navigate} /> :
                            <Outlet />}
            </Box>
        </Flex>
    );
};

const ManageProfile = () => {
    // ... (ManageProfile component remains the same)
};

const Rewards = ({ user, createdTournaments, navigate }) => {
    // ... (Rewards component remains the same)
};

const ManageTournaments = ({ user, createdTournaments, navigate }) => {
    return (
        <Box>
            <Heading as="h3" size="xl" marginBottom="1rem">Manage Your Tournaments</Heading>
            {createdTournaments.length > 0 ? (
                <SimpleGrid columns={[1, 2, 3]} spacing={6}>
                    {createdTournaments.map(tournament => (
                        <Box key={tournament.id} p={6} borderWidth="1px" borderRadius="lg" boxShadow="lg" bg="white" color="gray.800">
                            <Heading as="h2" size="lg" mb={2}>{tournament.name}</Heading>
                            <Badge colorScheme="blue" mb={2}>{tournament.game}</Badge>
                            <Text><strong>Registration:</strong> {new Date(tournament.registrationStart).toLocaleDateString()} - {new Date(tournament.registrationEnd).toLocaleDateString()}</Text>
                            <Text><strong>Prize Pool:</strong> 💎{tournament.prizePool}</Text>
                            <Text><strong>Max Slots:</strong> {tournament.maxSlots}</Text>
                            <Text noOfLines={2}><strong>Description:</strong> {tournament.description}</Text>
                            {tournament.logo && <Image src={`https://api.senc.in/uploads/${tournament.logo}`} alt={`${tournament.name} logo`} my={4} borderRadius="md" />}
                            <Button colorScheme="blue" onClick={() => navigate(`/manage-tournament/${tournament.id}`)} mt={4}>Manage</Button>
                        </Box>
                    ))}
                </SimpleGrid>
            ) : (
                <Text>You haven't created any tournaments yet.</Text>
            )}
        </Box>
    );
};

export { Dashboard, ManageProfile, Rewards, ManageTournaments };

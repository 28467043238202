import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, Button, Form, Input, Upload, Modal, Row, Col, Typography, Space, Divider } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const ViewTournaments = () => {
    const [tournament, setTournament] = useState(null);
    const [registeredTournaments, setRegisteredTournaments] = useState([]);
    const [message, setMessage] = useState('');
    const [showRegistrationForm, setShowRegistrationForm] = useState(false);
    const [formData, setFormData] = useState({
        teamName: '',
        teamLogo: null,
        phoneNumber: '',
        whatsappNumber: '',
        players: Array(6).fill({ name: '', inGameName: '', userId: '', serverId: '' }),
        tournamentId: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false); // State to manage button disabled state
    const tournamentId = '12'; // Replace with the specific tournament ID you want to display

    useEffect(() => {
        axios.get(`https://api.senc.in/api/tournaments/${tournamentId}`)
            .then((response) => setTournament(response.data))
            .catch((error) => console.error('Error fetching tournament:', error));

        axios.get('https://api.senc.in/api/teams/registered')
            .then((response) => setRegisteredTournaments(response.data))
            .catch((error) => console.error('Error fetching registered tournaments:', error));
    }, [tournamentId]);

    const handleRegisterClick = (tournamentId) => {
        if (registeredTournaments.includes(tournamentId)) {
            toast.error('You are already registered for this tournament.');
            return;
        }
        setFormData({ ...formData, tournamentId });
        setShowRegistrationForm(true);
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'teamLogo') {
            setFormData({ ...formData, [name]: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleUploadChange = ({ file }) => {
        setFormData({ ...formData, teamLogo: file });
    };

    const handlePlayerChange = (index, e) => {
        const { name, value } = e.target;
        const updatedPlayers = formData.players.map((player, i) =>
            i === index ? { ...player, [name]: value } : player
        );
        setFormData({ ...formData, players: updatedPlayers });
    };

    const checkPhoneNumberExists = async (phoneNumber, tournamentId) => {
        try {
            const response = await axios.post('https://api.senc.in/api/teams/check-phone', {
                phoneNumber,
                tournamentId
            });

            return response.data.exists;
        } catch (error) {
            console.error('Error checking phone number:', error);
            return false;
        }
    };

    const handleSubmit = async (values) => {
        setIsSubmitting(true); // Disable the submit button

        const phoneNumberExists = await checkPhoneNumberExists(values.phoneNumber, formData.tournamentId);
        if (phoneNumberExists) {
            toast.error('A team with this phone number is already registered.');
            setIsSubmitting(false); // Re-enable the submit button
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('teamName', values.teamName);
        if (formData.teamLogo) {
            formDataToSend.append('teamLogo', formData.teamLogo);
        }
        formDataToSend.append('phoneNumber', values.phoneNumber);
        formDataToSend.append('whatsappNumber', values.whatsappNumber);
        formDataToSend.append('players', JSON.stringify(formData.players));
        formDataToSend.append('tournamentId', formData.tournamentId);

        try {
            const response = await axios.post('https://api.senc.in/api/teams/register', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setMessage(response.data.message);
            toast.success('Successfully registered for the tournament!');
            setShowRegistrationForm(false);
        } catch (error) {
            console.error('Error registering team:', error);
            setMessage('Error registering team');
        } finally {
            setIsSubmitting(false); // Re-enable the submit button
        }
    };

    const isRegistrationClosed = (registrationEnd) => {
        const currentDate = new Date();
        const endDate = new Date(registrationEnd);
        return currentDate > endDate;
    };

    return (
        <div style={{ padding: '2rem', paddingTop: '6rem', backgroundColor: '#f0f2f5', minHeight: '100vh' }}>
            <ToastContainer />
            {message && <Text type="danger" style={{ textAlign: 'center', display: 'block', marginBottom: '1rem' }}>{message}</Text>}
            {tournament && (
                <Card
                    title={tournament.name}
                    extra={tournament.logo && <img src={`https://api.senc.in/uploads/${tournament.logo}`} alt={`${tournament.name} logo`} style={{ width: '100px', borderRadius: '50%' }} />}
                    style={{ maxWidth: '800px', margin: '0 auto', marginBottom: '2rem' }}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Text strong>Game:</Text> {tournament.game}
                        </Col>
                        <Col span={12}>
                            <Text strong>Registration:</Text> {new Date(tournament.registrationStart).toLocaleString()} - {new Date(tournament.registrationEnd).toLocaleString()}
                        </Col>
                        <Col span={12}>
                            <Text strong>Prize Pool:</Text> 💎 {tournament.prizePool}
                        </Col>
                        <Col span={12}>
                            <Text strong>Slots:</Text> {tournament.registered_teams} / {tournament.maxSlots}
                        </Col>
                    </Row>
                    <Divider />
                    <Text>{tournament.description}</Text>
                    <Divider />
                    <Space>
                        
                        {isRegistrationClosed(tournament.registrationEnd) ? (
                            <Button type="default" disabled>Closed</Button>
                        ) : (
                            <Button type="primary" onClick={() => handleRegisterClick(tournament.id)}>Register</Button>
                        )}
                    </Space>
                </Card>
            )}
            <Modal
                title="Register Team"
                visible={showRegistrationForm}
                onCancel={() => setShowRegistrationForm(false)}
                footer={null}
            >
                <Form layout="vertical" onFinish={handleSubmit}>
                    <Form.Item label="Team Name" name="teamName" rules={[{ required: true, message: 'Please input your team name!' }]}>
                        <Input name="teamName" value={formData.teamName} onChange={handleChange} />
                    </Form.Item>
                    <Form.Item label="Team Logo" name="teamLogo">
                        <Upload name="teamLogo" beforeUpload={() => false} onChange={handleUploadChange}>
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item label="Phone Number" name="phoneNumber" rules={[{ required: true, message: 'Please input your phone number!' }]}>
                        <Input name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
                    </Form.Item>
                    <Form.Item label="WhatsApp Number" name="whatsappNumber" rules={[{ required: true, message: 'Please input your WhatsApp number!' }]}>
                        <Input name="whatsappNumber" value={formData.whatsappNumber} onChange={handleChange} />
                    </Form.Item>
                    {formData.players.map((player, index) => (
                        <Space key={index} direction="vertical" style={{ width: '100%' }}>
                            <Title level={5}>Player {index + 1}</Title>
                            <Form.Item label="Name" name={`playerName${index}`} rules={[{ required: index < 5, message: 'Please input player name!' }]}>
                                <Input name="name" value={player.name} onChange={(e) => handlePlayerChange(index, e)} />
                            </Form.Item>
                            <Form.Item label="In-Game Name" name={`playerInGameName${index}`} rules={[{ required: index < 5, message: 'Please input in-game name!' }]}>
                                <Input name="inGameName" value={player.inGameName} onChange={(e) => handlePlayerChange(index, e)} />
                            </Form.Item>
                            <Form.Item label="User ID" name={`playerUserId${index}`} rules={[{ required: index < 5, message: 'Please input user ID!' }]}>
                                <Input name="userId" value={player.userId} onChange={(e) => handlePlayerChange(index, e)} />
                            </Form.Item>
                            <Form.Item label="Server ID" name={`playerServerId${index}`} rules={[{ required: index < 5, message: 'Please input server ID!' }]}>
                                <Input name="serverId" value={player.serverId} onChange={(e) => handlePlayerChange(index, e)} />
                            </Form.Item>
                        </Space>
                    ))}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }} disabled={isSubmitting}>Submit</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ViewTournaments;

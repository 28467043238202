import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from "styled-components";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewTournaments = () => {
    const [tournament, setTournament] = useState(null);
    const [registeredTournaments, setRegisteredTournaments] = useState([]);
    const [message, setMessage] = useState('');
    const [showRegistrationForm, setShowRegistrationForm] = useState(false);
    const [formData, setFormData] = useState({
        teamName: '',
        teamLogo: null,
        phoneNumber: '',
        whatsappNumber: '',
        players: Array(6).fill({ name: '', inGameName: '', userId: '', serverId: '' }),
        tournamentId: ''
    });
    const tournamentId = '5'; // Replace with the specific tournament ID you want to display

    useEffect(() => {
        axios.get(`https://api.senc.in/api/tournaments/${tournamentId}`)
            .then((response) => setTournament(response.data))
            .catch((error) => console.error('Error fetching tournament:', error));

        axios.get('https://api.senc.in/api/teams/registered')
            .then((response) => setRegisteredTournaments(response.data))
            .catch((error) => console.error('Error fetching registered tournaments:', error));
    }, [tournamentId]);

    const handleRegisterClick = (tournamentId) => {
        if (registeredTournaments.includes(tournamentId)) {
            toast.error('You are already registered for this tournament.');
            return;
        }
        setFormData({ ...formData, tournamentId });
        setShowRegistrationForm(true);
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'teamLogo') {
            setFormData({ ...formData, [name]: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handlePlayerChange = (index, e) => {
        const { name, value } = e.target;
        const updatedPlayers = formData.players.map((player, i) =>
            i === index ? { ...player, [name]: value } : player
        );
        setFormData({ ...formData, players: updatedPlayers });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('teamName', formData.teamName);
        if (formData.teamLogo) {
            formDataToSend.append('teamLogo', formData.teamLogo);
        }
        formDataToSend.append('phoneNumber', formData.phoneNumber);
        formDataToSend.append('whatsappNumber', formData.whatsappNumber);
        formDataToSend.append('players', JSON.stringify(formData.players));
        formDataToSend.append('tournamentId', formData.tournamentId);

        try {
            const response = await axios.post('https://api.senc.in/api/teams/register', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setMessage(response.data.message);
            toast.success('Successfully registered for the tournament!');
            setShowRegistrationForm(false);
        } catch (error) {
            console.error('Error registering team:', error);
            setMessage('Error registering team');
        }
    };

    const isRegistrationClosed = (registrationEnd) => {
        const currentDate = new Date();
        const endDate = new Date(registrationEnd);
        return currentDate > endDate;
    };

    return (
        <PageContainer>
            {message && <Message>{message}</Message>}
            {tournament && (
                <TournamentCard>
                    <TournamentHeader>
                        <h2>{tournament.name}</h2>
                        {tournament.logo && <TournamentLogo src={`https://api.senc.in/uploads/${tournament.logo}`} alt={`${tournament.name} logo`} />}
                    </TournamentHeader>
                    <TournamentInfo>
                        <InfoItem>
                            <Label>Game:</Label>
                            <Value>{tournament.game}</Value>
                        </InfoItem>
                        <InfoItem>
                            <Label>Registration:</Label>
                            <Value>
                                {new Date(tournament.registrationStart).toLocaleString()} -
                                {new Date(tournament.registrationEnd).toLocaleString()}
                            </Value>
                        </InfoItem>
                        <InfoItem>
                            <Label>Prize Pool:</Label>
                            <Value>💎 {tournament.prizePool}</Value>
                        </InfoItem>
                        <InfoItem>
                            <Label>Slots:</Label>
                            <Value>{tournament.registered_teams} / {tournament.maxSlots}</Value>
                        </InfoItem>
                    </TournamentInfo>
                    <Description>{tournament.description}</Description>
                    {isRegistrationClosed(tournament.registrationEnd) ? (
                        <ClosedButton disabled>Closed</ClosedButton>
                    ) : (
                        <RegisterButton onClick={() => handleRegisterClick(tournament.id)}>Register</RegisterButton>
                    )}
                </TournamentCard>
            )}
            {showRegistrationForm && (
                <RegistrationSection>
                    <h2>Register Team</h2>
                    <RegistrationForm onSubmit={handleSubmit}>
                        <Input
                            type="text"
                            name="teamName"
                            value={formData.teamName}
                            onChange={handleChange}
                            placeholder="Team Name"
                            required
                        />
                        <FileInput
                            type="file"
                            name="teamLogo"
                            onChange={handleChange}
                        />
                        <Input
                            type="text"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            placeholder="Phone Number"
                            required
                        />
                        <Input
                            type="text"
                            name="whatsappNumber"
                            value={formData.whatsappNumber}
                            onChange={handleChange}
                            placeholder="WhatsApp Number"
                            required
                        />
                        {formData.players.map((player, index) => (
                            <PlayerInputGroup key={index}>
                                <Input
                                    type="text"
                                    name="name"
                                    value={player.name}
                                    onChange={(e) => handlePlayerChange(index, e)}
                                    placeholder={`Player ${index + 1} Name`}
                                    required={index < 5}
                                />
                                <Input
                                    type="text"
                                    name="inGameName"
                                    value={player.inGameName}
                                    onChange={(e) => handlePlayerChange(index, e)}
                                    placeholder="In-Game Name"
                                    required={index < 5}
                                />
                                <Input
                                    type="text"
                                    name="userId"
                                    value={player.userId}
                                    onChange={(e) => handlePlayerChange(index, e)}
                                    placeholder="User ID"
                                    required={index < 5}
                                />
                                <Input
                                    type="text"
                                    name="serverId"
                                    value={player.serverId}
                                    onChange={(e) => handlePlayerChange(index, e)}
                                    placeholder="Server ID"
                                    required={index < 5}
                                />
                            </PlayerInputGroup>
                        ))}
                        <SubmitButton type="submit">Submit</SubmitButton>
                    </RegistrationForm>
                </RegistrationSection>
            )}
        </PageContainer>
    );
};

export default ViewTournaments;

// ... (keep all the existing styled components)

const RegistrationSection = styled.div`
    background: #fff;
    margin: 2rem auto;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;

    @media (max-width: 768px) {
        padding: 1rem;
        margin: 1rem auto;
    }

    h2 {
        margin-bottom: 1.5rem;
        color: #333;
    }
`;



const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f0f2f5;
    padding: 2rem;
`;

const Message = styled.p`
    color: #dc3545;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1rem;
`;

const TournamentCard = styled.div`
    background: #fff;
    margin: 1rem auto;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    max-width: 800px;
    width: 100%;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    @media (max-width: 768px) {
        padding: 0.5rem;
        margin: 0.5rem auto;
    }
`;

const TournamentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    h2 {
        margin: 0;
        font-size: 2rem;
        color: #333;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;

        h2 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }
    }
`;

const TournamentLogo = styled.img`
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        width: 80px;
        height: 80px;
    }
`;

const TournamentInfo = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const Description = styled.p`
    color: #555;
    line-height: 1.6;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`;

const Button = styled.button`
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.1s;
    width: 100%;

    &:hover {
        transform: translateY(-2px);
    }

    &:active {
        transform: translateY(0);
    }

    @media (max-width: 768px) {
        padding: 0.6rem 1.2rem;
        font-size: 0.9rem;
    }
`;

const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.span`
    font-weight: bold;
    color: #666;
    margin-bottom: 0.25rem;
`;

const Value = styled.span`
    color: #333;
`;





const RegisterButton = styled(Button)`
    background-color: #007bff;
    color: white;

    &:hover {
        background-color: #0056b3;
    }
`;

const ClosedButton = styled(Button)`
    background-color: #6c757d;
    color: white;
    cursor: not-allowed;
`;

const ModalContent = styled.div`
    padding: 2rem;
`;

const RegistrationForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const Input = styled.input`
    padding: 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
    width: 100%;

    &:focus {
        outline: none;
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    @media (max-width: 768px) {
        padding: 0.5rem;
        font-size: 0.9rem;
    }
`;

const FileInput = styled(Input)`
    &::file-selector-button {
        padding: 0.5rem 1rem;
        background-color: #6c757d;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
            background-color: #5a6268;
        }
    }

    @media (max-width: 768px) {
        &::file-selector-button {
            padding: 0.4rem 0.8rem;
            font-size: 0.9rem;
        }
    }
`;

const PlayerInputGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    
`;


const SubmitButton = styled(Button)`
    background-color: #28a745;
    color: white;
    margin-top: 1rem;
    width: 100%;

    &:hover {
        background-color: #218838;
    }

    @media (max-width: 768px) {
        padding: 0.6rem 1.2rem;
        font-size: 0.9rem;
    }
`;

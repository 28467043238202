import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, FormControl, FormLabel, Input, Heading, Text, VStack, useColorModeValue } from '@chakra-ui/react';

const Register = () => {
    const [formData, setFormData] = useState({ username: '', email: '', password: '' });
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.senc.in/auth/register', formData);
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response?.data?.message || 'Registration failed');
        }
    };

    // Theme colors
    const bgGradient = useColorModeValue('linear(to-r, gray.100, gray.300)', 'linear(to-r, gray.700, gray.900)');
    const color = useColorModeValue('gray.800', 'white');
    const headingColor = useColorModeValue('teal.500', 'teal.300');
    const placeholderColor = useColorModeValue('gray.500', 'gray.300');
    const messageColor = useColorModeValue('green.600', 'green.300');

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            bgGradient={bgGradient}
            color={color}
            px={6}
            py={8}
        >
            <Heading as="h2" size="xl" mb={8} textAlign="center" color={headingColor}>
                Create an Account
            </Heading>
            <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
                <VStack spacing={6}>
                    <FormControl id="username" isRequired>
                        <FormLabel>Username</FormLabel>
                        <Input
                            type="text"
                            name="username"
                            placeholder="Enter your username"
                            value={formData.username}
                            onChange={handleChange}
                            focusBorderColor="teal.400"
                            variant="filled"
                            _placeholder={{ color: placeholderColor }}
                        />
                    </FormControl>
                    <FormControl id="email" isRequired>
                        <FormLabel>Email Address</FormLabel>
                        <Input
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleChange}
                            focusBorderColor="teal.400"
                            variant="filled"
                            _placeholder={{ color: placeholderColor }}
                        />
                    </FormControl>
                    <FormControl id="password" isRequired>
                        <FormLabel>Password</FormLabel>
                        <Input
                            type="password"
                            name="password"
                            placeholder="Enter your password"
                            value={formData.password}
                            onChange={handleChange}
                            focusBorderColor="teal.400"
                            variant="filled"
                            _placeholder={{ color: placeholderColor }}
                        />
                    </FormControl>
                    <Button type="submit" colorScheme="teal" size="lg" width="100%">
                        Register
                    </Button>
                </VStack>
            </form>
            {message && (
                <Text mt={6} fontSize="md" color={messageColor} textAlign="center">
                    {message}
                </Text>
            )}
        </Box>
    );
};

export default Register;

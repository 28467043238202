// src/pages/ContactUs.js
import React from 'react';

const ContactUs = () => {
    return (
        <div style={pageStyle}>
            <h2>Contact Us</h2>
            <p>Here you can add your contact details...</p>
        </div>
    );
};

const pageStyle = {
    padding: '2rem',
};

export default ContactUs;

import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
    Box, Button, FormControl, FormLabel, Input, Heading, Text, VStack, Link, IconButton, useColorMode,
} from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';
import { auth, provider, signInWithPopup } from './middleware/firebaseConfig';

const Login = () => {
    const { control, handleSubmit, setError } = useForm();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const { colorMode, toggleColorMode } = useColorMode();

    const onSubmit = async (data) => {
        try {
            const response = await fetch('https://api.senc.in/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            const result = await response.json();

            if (response.ok) {
                const { token, message, isAdmin, role, userId } = result;
                sessionStorage.setItem('token', token);
                sessionStorage.setItem('role', role);
                sessionStorage.setItem('userId', userId);
                sessionStorage.setItem('isAdmin', isAdmin);

                setMessage(message);
                setStatus('success');
                window.dispatchEvent(new Event('loginStateChange'));
                navigate('/dashboard');
            } else {
                setMessage(result.message || 'Login failed');
                setStatus('error');
                setError('username', { type: 'manual', message: result.message });
            }
        } catch (error) {
            setMessage('Login failed');
            setStatus('error');
        }
    };

    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            const response = await fetch('https://api.senc.in/auth/google-login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: user.email }),
            });
            const data = await response.json();

            if (response.ok) {
                const { token, message, role, isAdmin, userId } = data;
                sessionStorage.setItem('token', token);
                sessionStorage.setItem('role', role);
                sessionStorage.setItem('isAdmin', isAdmin);
                sessionStorage.setItem('userId', userId);

                setMessage(message);
                setStatus('success');
                window.dispatchEvent(new Event('loginStateChange'));
                navigate('/dashboard');
            } else {
                setMessage(data.message || 'Google login failed');
                setStatus('error');
            }
        } catch (error) {
            console.error('Error during Google login:', error);
            setMessage('Google login failed');
            setStatus('error');
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            bg={colorMode === 'light' ? 'gray.100' : 'gray.900'}
            color={colorMode === 'light' ? 'black' : 'white'}
            transition="background-color 0.3s ease"
            padding="1rem"
        >
            <IconButton
                icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                onClick={toggleColorMode}
                variant="ghost"
                colorScheme="orange"
                aria-label="Toggle Theme"
                alignSelf="flex-end"
                mb={4}
            />
            <Heading mb={6}>Login</Heading>
            <Box
                as="form"
                onSubmit={handleSubmit(onSubmit)}
                width="100%"
                maxWidth="400px"
                p={6}
                boxShadow="lg"
                borderRadius="md"
                bg={colorMode === 'light' ? 'white' : 'gray.800'}
            >
                <VStack spacing={4}>
                    <FormControl>
                        <FormLabel htmlFor="username">Username</FormLabel>
                        <Controller
                            name="username"
                            control={control}
                            rules={{ required: 'Please input your username!' }}
                            render={({ field }) => <Input id="username" {...field} />}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel htmlFor="password">Password</FormLabel>
                        <Controller
                            name="password"
                            control={control}
                            rules={{ required: 'Please input your password!' }}
                            render={({ field }) => <Input id="password" type="password" {...field} />}
                        />
                    </FormControl>

                    <Button type="submit" width="100%" colorScheme="blue">Login</Button>
                    <Button width="100%" colorScheme="red" onClick={handleGoogleLogin}>Login with Google</Button>
                </VStack>
                <Text mt={4} textAlign="center">
                    Don't have an account?{' '}
                    <Link as={RouterLink} to="/register" color="teal.300" _hover={{ textDecoration: 'underline' }}>
                        Register now
                    </Link>
                </Text>
            </Box>

            {message && (
                <Text mt={4} color={status === 'success' ? 'green.500' : 'red.500'}>
                    {message}
                </Text>
            )}
        </Box>
    );
};

export default Login;

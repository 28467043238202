import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, isAdmin }) => {
    const isLoggedIn = sessionStorage.getItem('token'); // Check if user is logged in
    const userIsAdmin = sessionStorage.getItem('isAdmin'); // Check if user is admin

    if (!isLoggedIn) {
        return <Navigate to="/login" />;
    }

    if (isAdmin && userIsAdmin !== 'true') {
        return <Navigate to="/Login" />;
    }

    return children;
};

export default ProtectedRoute;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { useParams } from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Text,
    VStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Image,
    useDisclosure,
} from '@chakra-ui/react';

const ViewPrime = () => {
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedLogo, setSelectedLogo] = useState(null);
    const { tournamentId } = useParams(); // Use dynamic id from useParams
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const fetchTeams = async () => {
            try {
                const response = await axios.get(`https://api.senc.in/api/tournaments/4/teams`);
                setTeams(response.data);
                setLoading(false);
            } catch (error) {
                setError('Error fetching registered teams');
                setLoading(false);
            }
        };

        fetchTeams();
    }, [tournamentId]);

    const exportToExcel = () => {
        const data = teams.map(team => ({
            TeamName: team.name,
            LeaderID: team.leader_id,
            Players: team.players.map(player => `${player.name} (${player.in_game_name})`).join(', '),
            Logo: team.logo,
            UserID: team.user_id,
            ServerID: team.server_id
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Teams');
        XLSX.writeFile(workbook, 'registered_teams.xlsx');
    };

    const viewLogo = (logoUrl) => {
        const fullLogoUrl = `https://api.senc.in/uploads/${logoUrl}`;
        setSelectedLogo(fullLogoUrl);
        onOpen();
    };

    if (loading) return <CircularProgress style={{ margin: '20px auto', display: 'block' }} />;
    if (error) return <Text color="red.500" fontSize="lg">{error}</Text>;

    return (
        <Container maxW="container.xl" py={4}>
            <VStack spacing={4} align="stretch">
                <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                    Registered Teams
                </Text>

                <Button
                    colorScheme="blue"
                    onClick={exportToExcel}
                    mb={4}
                >
                    Export to Excel
                </Button>

                <Box overflowX="auto">
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th><strong>Team Name</strong></Th>
                                <Th><strong>Phone Number</strong></Th>
                                <Th><strong>WhatsApp Number</strong></Th>
                                <Th><strong>Players</strong></Th>
                                <Th><strong>Logo</strong></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {teams.map((team) => (
                                <Tr key={team.id}>
                                    <Td>{team.name}</Td>
                                    <Td>{team.phone_number}</Td>
                                    <Td>{team.whatsapp_number}</Td>
                                    <Td>
                                        {team.players.map(player => (
                                            <Box key={player.id}>
                                                {player.name} ({player.in_game_name}) - User ID: {player.user_id}, Server ID: {player.server_id}
                                            </Box>
                                        ))}
                                    </Td>
                                    <Td>
                                        <Button colorScheme="blue" onClick={() => viewLogo(team.logo)}>
                                            View Logo
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </VStack>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Team Logo</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedLogo && <Image src={selectedLogo} alt="Team Logo" />}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default ViewPrime;

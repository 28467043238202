import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Text, VStack, HStack } from '@chakra-ui/react';

function ViewBrackets() {
    const [rounds, setRounds] = useState([]);
    const [teams, setTeams] = useState({});
    const { tournamentId } = useParams();

    useEffect(() => {
        const fetchBrackets = async () => {
            try {
                // Fetch bracket data for the tournament
                const bracketResponse = await fetch(`https://api.senc.in/api/tournaments/${tournamentId}/brackets`);
                const brackets = await bracketResponse.json();

                // Fetch team data for the tournament
                const teamResponse = await fetch(`https://api.senc.in/api/tournaments/${tournamentId}/teams`);
                const teamData = await teamResponse.json();

                // Map team IDs to names for easy lookup
                const teamMap = teamData.reduce((map, team) => {
                    map[team.id] = team.name;
                    return map;
                }, {});
                setTeams(teamMap);

                // Organize matches into rounds
                const roundsData = brackets.reduce((roundMap, match) => {
                    const roundMatches = roundMap[match.round] || [];
                    roundMatches.push(match);
                    roundMap[match.round] = roundMatches;
                    return roundMap;
                }, {});

                // Format rounds and matches with team details
                const formattedRounds = Object.entries(roundsData).map(([round, matches]) => ({
                    round: parseInt(round),
                    matches: matches.map(match => ({
                        id: `${match.tournamentId}-${match.round}-${match.matchNumber}`,
                        name: `Match ${match.matchNumber}`,
                        sides: {
                            home: {
                                team: { id: match.team1Id, name: teamMap[match.team1Id] || `Team ${match.team1Id}` },
                                score: { score: match.team1Score || 0 },
                            },
                            visitor: {
                                team: { id: match.team2Id, name: teamMap[match.team2Id] || `Team ${match.team2Id}` },
                                score: { score: match.team2Score || 0 },
                            },
                        },
                        winnerId: match.winnerId,
                    })),
                }));

                setRounds(formattedRounds.sort((a, b) => a.round - b.round));
            } catch (error) {
                console.error('Error loading bracket or team data:', error);
            }
        };

        fetchBrackets();
    }, [tournamentId]);

    const MatchBox = ({ match }) => (
        <Box p={3} borderWidth="1px" borderRadius="md" bg="gray.700" width="220px" textAlign="center">
            <Text fontWeight="bold">{match.sides.home.team.name} ({match.sides.home.score.score})</Text>
            <Text>vs</Text>
            <Text fontWeight="bold">{match.sides.visitor.team.name} ({match.sides.visitor.score.score})</Text>
            {match.winnerId && (
                <Text mt={3} fontWeight="bold" color="green.300">
                    Winner: {teams[match.winnerId] || `Team ${match.winnerId}`}
                </Text>
            )}
        </Box>
    );

    return (
        <Box pt={36} px={6} pb={8} bg="gray.800" color="white" minH="100vh" overflowX="auto">
            <HStack spacing={12} align="flex-start" justify="center">
                {rounds.map((round) => (
                    <VStack key={round.round} spacing={5} align="center">
                        <Text fontWeight="bold" fontSize="lg">Round {round.round}</Text>
                        {round.matches.map((match) => (
                            <MatchBox key={match.id} match={match} />
                        ))}
                    </VStack>
                ))}
            </HStack>
        </Box>
    );
}

export default ViewBrackets;

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD0sVLQTFyIbfTpZ1QcRF9dQi5zHRGxvKM",
    authDomain: "senc-bb394.firebaseapp.com",
    projectId: "senc-bb394",
    storageBucket: "senc-bb394.appspot.com",
    messagingSenderId: "406678742407",
    appId: "1:406678742407:web:d8f45a2cb9c5815e915f22",
    measurementId: "G-9HY0YBK6KT"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider, signInWithPopup };
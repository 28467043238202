// src/pages/TermsAndConditions.js
import React from 'react';
import styled from 'styled-components';

const TermsAndConditions = () => {
    const lastUpdated = new Date().toLocaleDateString();

    return (
        <PageContainer>
            <Title>Terms and Conditions</Title>
            <Paragraph>
                <strong>SENC TERMS AND CONDITIONS</strong><br />
                Last Updated: {lastUpdated}
            </Paragraph>
            <Paragraph>
                Welcome to SENC. By accessing or using our website and services, you agree to be bound by the following terms and conditions ("Terms"). If you do not agree with any of these terms, you must discontinue use of our services.
            </Paragraph>
            <Section>
                <SectionTitle>1. Acceptance of Terms</SectionTitle>
                <Paragraph>
                    By using the SENC website or any related services (collectively, "Services"), you agree to comply with these Terms and Conditions. SENC reserves the right to modify these Terms at any time. Your continued use of the Services indicates your acceptance of any modifications.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>2. Use of Services</SectionTitle>
                <Paragraph>
                    <strong>2.1 Eligibility:</strong> You must be at least 18 years old to use our Services.
                </Paragraph>
                <Paragraph>
                    <strong>2.2 Account Creation:</strong> You may be required to create an account to access certain features. You agree to provide accurate and complete information and to keep your account information updated.
                </Paragraph>
                <Paragraph>
                    <strong>2.3 Prohibited Use:</strong> You agree not to engage in any unlawful or prohibited activities, including but not limited to:
                </Paragraph>
                <UnorderedList>
                    <ListItem>Interfering with the operation of the Services</ListItem>
                    <ListItem>Uploading malicious code</ListItem>
                    <ListItem>Engaging in fraudulent activities</ListItem>
                    <ListItem>Violating intellectual property rights of SENC or any third parties</ListItem>
                </UnorderedList>
            </Section>
            <Section>
                <SectionTitle>3. Intellectual Property</SectionTitle>
                <Paragraph>
                    All content, including but not limited to text, graphics, logos, images, and software, is the property of SENC and is protected by intellectual property laws. You may not copy, modify, distribute, or exploit the content without prior written consent from SENC.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>4. User Content</SectionTitle>
                <Paragraph>
                    <strong>4.1 Ownership:</strong> You retain ownership of the content you submit through the Services ("User Content").
                </Paragraph>
                <Paragraph>
                    <strong>4.2 License:</strong> By submitting User Content, you grant SENC a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and display the content.
                </Paragraph>
                <Paragraph>
                    <strong>4.3 Responsibility:</strong> You are solely responsible for the content you upload or share via the Services.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>5. Payment Terms</SectionTitle>
                <Paragraph>
                    Certain features may be subject to fees. All payments must be made promptly, and you are responsible for providing accurate payment information. SENC reserves the right to modify fees and billing methods at any time.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>6. Termination</SectionTitle>
                <Paragraph>
                    SENC reserves the right to terminate or suspend your account and access to the Services at our sole discretion, without prior notice, for violations of these Terms or for any reason.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>7. Limitation of Liability</SectionTitle>
                <Paragraph>
                    SENC will not be liable for any direct, indirect, incidental, or consequential damages arising out of your use of the Services. The Services are provided "as is" without any warranties of any kind.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>8. Governing Law</SectionTitle>
                <Paragraph>
                    These Terms shall be governed by and construed in accordance with the laws of India. Any disputes arising under these Terms shall be subject to the exclusive jurisdiction of the courts located in Indian Jurisdiction.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>9. Dispute Resolution</SectionTitle>
                <Paragraph>
                    Any dispute arising from these Terms will be resolved through arbitration in accordance with the Arbitration and Conciliation Act, 1996. The seat of arbitration shall be India.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>10. Changes to the Terms</SectionTitle>
                <Paragraph>
                    SENC reserves the right to update or change these Terms at any time. All updates will be posted on this page with the "Last Updated" date.
                </Paragraph>
            </Section>
            <Section>
                <SectionTitle>11. Contact Information</SectionTitle>
                <Paragraph>
                    If you have any questions regarding these Terms, please contact us at adh@senc.in.
                </Paragraph>
            </Section>
        </PageContainer>
    );
};

const PageContainer = styled.div`
    padding: 2rem;
`;

const Title = styled.h2`
    margin-bottom: 1rem;
`;

const Section = styled.section`
    margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h3`
    margin-bottom: 0.5rem;
`;

const Paragraph = styled.p`
    margin-bottom: 1rem;
`;

const UnorderedList = styled.ul`
    margin-left: 1.5rem;
    margin-bottom: 1rem;
`;

const ListItem = styled.li`
    margin-bottom: 0.5rem;
`;

export default TermsAndConditions;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Heading,
    Image,
    Input,
    Text,
    VStack,
    useToast,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Grid,
    GridItem,
    useColorMode,
    IconButton,
} from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';

const ViewTournaments = () => {
    const [tournaments, setTournaments] = useState([]);
    const [registeredTournaments, setRegisteredTournaments] = useState([]);
    const [message, setMessage] = useState('');
    const [formData, setFormData] = useState({
        teamName: '',
        teamLogo: null,
        phoneNumber: '',
        whatsappNumber: '',
        players: Array(6).fill({ name: '', inGameName: '', userId: '', serverId: '' }),
        tournamentId: ''
    });
    const [viewingBrackets, setViewingBrackets] = useState(false);
    const [matches, setMatches] = useState([]);
    const [bracketsOpen, setBracketsOpen] = useState({});

    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { colorMode, toggleColorMode } = useColorMode();

    useEffect(() => {
        const fetchTournaments = async () => {
            try {
                const response = await axios.get('https://api.senc.in/api/tournaments');
                const tournamentsData = response.data;

                const bracketsOpenMap = tournamentsData.reduce((acc, tournament) => {
                    acc[tournament.id] = tournament.bracketOpen;
                    return acc;
                }, {});

                setTournaments(tournamentsData);
                setBracketsOpen(bracketsOpenMap);
            } catch (error) {
                console.error('Error fetching tournaments:', error);
            }
        };

        const fetchRegisteredTournaments = async () => {
            try {
                const response = await axios.get('https://api.senc.in/api/teams/registered');
                setRegisteredTournaments(response.data);
            } catch (error) {
                console.error('Error fetching registered tournaments:', error);
            }
        };

        fetchTournaments();
        fetchRegisteredTournaments();
    }, []);

    const handleRegisterClick = (tournamentId) => {
        if (registeredTournaments.includes(tournamentId)) {
            toast({
                title: 'Error',
                description: 'You are already registered for this tournament.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        setFormData({ ...formData, tournamentId });
        onOpen();
        setViewingBrackets(false);
    };

    const handleViewBrackets = (tournamentId) => {
        navigate(`/view-brackets/${tournamentId}`);
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'teamLogo') {
            setFormData({ ...formData, [name]: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const checkPhoneNumberExists = async (phoneNumber, tournamentId) => {
        try {
            const response = await axios.post('https://api.senc.in/api/teams/check-phone', {
                phoneNumber,
                tournamentId
            });

            return response.data.exists;
        } catch (error) {
            console.error('Error checking phone number:', error);
            return false;
        }
    };

    const handlePlayerChange = (index, e) => {
        const { name, value } = e.target;
        const updatedPlayers = formData.players.map((player, i) =>
            i === index ? { ...player, [name]: value } : player
        );
        setFormData({ ...formData, players: updatedPlayers });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const phoneNumberExists = await checkPhoneNumberExists(formData.phoneNumber, formData.tournamentId);
        if (phoneNumberExists) {
            toast({
                title: 'Error',
                description: 'A team with this phone number is already registered.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('teamName', formData.teamName);
        if (formData.teamLogo) {
            formDataToSend.append('teamLogo', formData.teamLogo);
        }
        formDataToSend.append('phoneNumber', formData.phoneNumber);
        formDataToSend.append('whatsappNumber', formData.whatsappNumber);
        formDataToSend.append('players', JSON.stringify(formData.players));
        formDataToSend.append('tournamentId', formData.tournamentId);

        try {
            const response = await axios.post('https://api.senc.in/api/teams/register', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setMessage(response.data.message);
            toast({
                title: 'Success',
                description: 'Successfully registered for the tournament!',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            onClose();
        } catch (error) {
            console.error('Error registering team:', error);
            setMessage('Error registering team');
        }
    };

    const isRegistrationClosed = (registrationEnd) => {
        const currentDate = new Date();
        const endDate = new Date(registrationEnd);
        return currentDate > endDate;
    };

    const isBracketOpen = (bracketOpen) => {
        return bracketOpen === 1;
    };

    return (
        <Container maxW="container.xl" py={4} pt={16} position="relative" zIndex={1}>
            {/* Background Video */}
            <video
                autoPlay
                loop
                muted
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: -1,
                }}
            >
                <source src="https://docs.onlinehub.co.in/senchome.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {/* Theme Toggle Button */}
            <IconButton
                icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                aria-label="Toggle Theme"
                onClick={toggleColorMode}
                position="absolute"
                top={4}
                right={4}
                colorScheme="teal"
                mb={4}
            />

            <Heading as="h1" mb={4} color="gray.800">View Tournaments</Heading>
            {message && <Text color="green.500" textAlign="center" mb={4}>{message}</Text>}

            <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap={4}>
                {tournaments.map(tournament => (
                    <GridItem key={tournament.id}>
                        <Box p={4} borderWidth="1px" borderRadius="lg" bg="rgba(255, 255, 255, 0.1)" backdropFilter="blur(10px)" color="white" textAlign="center">
                            <Heading as="h2" size="md" mb={2} color="gray.100">{tournament.name}</Heading>
                            <Text><strong>Game:</strong> {tournament.game}</Text>
                            <Text><strong>Registration Start:</strong> {new Date(tournament.registrationStart).toLocaleString()}</Text>
                            <Text><strong>Registration End:</strong> {new Date(tournament.registrationEnd).toLocaleString()}</Text>
                            <Text><strong>Prize Pool:</strong> 💎{tournament.prizePool}</Text>
                            <Text><strong>Max Slots:</strong> {tournament.maxSlots}</Text>
                            <Text><strong>Filled Slots:</strong> {tournament.registered_teams}</Text>
                            <Text><strong>Description:</strong> {tournament.description}</Text>
                            {tournament.logo && <Image src={`https://api.senc.in/uploads/${tournament.logo}`} alt={`${tournament.name} logo`} my={4} />}
                            {isRegistrationClosed(tournament.registrationEnd) ? (
                                <Button colorScheme="gray" isDisabled>Closed</Button>
                            ) : (
                                <Button colorScheme="teal" onClick={() => handleRegisterClick(tournament.id)}>Register</Button>
                            )}
                            {isBracketOpen(tournament.bracketOpen) ? (
                                <Button colorScheme="blue" onClick={() => handleViewBrackets(tournament.id)}>View Brackets</Button>
                            ) : (
                                <Text color="red.500">Bracket not available yet</Text>
                            )}
                        </Box>
                    </GridItem>
                ))}
            </Grid>

            {/* Registration Modal */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Register for Tournament</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4} align="flex-start">
                            <Input
                                placeholder="Team Name"
                                name="teamName"
                                value={formData.teamName}
                                onChange={handleChange}
                            />
                            <Input
                                type="file"
                                name="teamLogo"
                                onChange={handleChange}
                            />
                            <Input
                                placeholder="Phone Number"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                            />
                            <Input
                                placeholder="WhatsApp Number"
                                name="whatsappNumber"
                                value={formData.whatsappNumber}
                                onChange={handleChange}
                            />
                            {formData.players.map((player, index) => (
                                <VStack key={index} spacing={2}>
                                    <Input
                                        placeholder={`Player ${index + 1} Name`}
                                        name="name"
                                        value={player.name}
                                        onChange={(e) => handlePlayerChange(index, e)}
                                    />
                                    <Input
                                        placeholder={`Player ${index + 1} In-Game Name`}
                                        name="inGameName"
                                        value={player.inGameName}
                                        onChange={(e) => handlePlayerChange(index, e)}
                                    />
                                </VStack>
                            ))}
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" onClick={handleSubmit}>Submit</Button>
                        <Button colorScheme="gray" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default ViewTournaments;

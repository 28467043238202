// client/src/pages/Squad.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Squad = () => {
    const [squad, setSquad] = useState(null);
    const [squadName, setSquadName] = useState('');
    const [description, setDescription] = useState('');
    const [logo, setLogo] = useState(null);
    const [message, setMessage] = useState('');
    const [usernameOrEmail, setUsernameOrEmail] = useState('');
    const [squadId, setSquadId] = useState('');
    const [query, setQuery] = useState('');
    const [squads, setSquads] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    const [isJoining, setIsJoining] = useState(false);

    useEffect(() => {
        // Fetch the user's squad if they have one
        const fetchSquad = async () => {
            try {
                const response = await axios.get('https://api.senc.in/api/squads/my-squad');
                setSquad(response.data);
            } catch (error) {
                console.error('Error fetching squad:', error);
            }
        };

        fetchSquad();
    }, []);

    const handleCreateSquad = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('name', squadName);
            formData.append('description', description);
            if (logo) {
                formData.append('logo', logo);
            }

            const response = await axios.post('https://api.senc.in/api/squads/create', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setMessage(`Squad ${response.data.name} created successfully!`);
            setSquad(response.data);
            setSquadName('');
            setDescription('');
            setLogo(null);
            setIsCreating(false);
        } catch (error) {
            setMessage('Error creating squad');
        }
    };

    const handleInvite = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:3001/api/squads/invite', { squadId: squad.id, usernameOrEmail });
            setMessage(response.data.message);
            setUsernameOrEmail('');
        } catch (error) {
            setMessage('Error inviting user');
        }
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.get(`http://localhost:3001/api/squads/search?query=${query}`);
            setSquads(response.data);
        } catch (error) {
            console.error('Error searching squads:', error);
        }
    };

    const handleApply = async (squadId) => {
        try {
            await axios.post('http://localhost:3001/api/squads/apply', { squadId });
            alert('Application sent');
        } catch (error) {
            console.error('Error applying to squad:', error);
        }
    };

    const handleKick = async (userId) => {
        try {
            await axios.post('http://localhost:3001/api/squads/kick', { squadId: squad.id, userId });
            setSquad(prevSquad => ({
                ...prevSquad,
                members: prevSquad.members.filter(member => member.id !== userId)
            }));
            alert('User kicked from squad');
        } catch (error) {
            console.error('Error kicking user:', error);
        }
    };

    if (!squad) {
        return (
            <div>
                <h2>Squad Management</h2>
                <button onClick={() => setIsCreating(true)}>Create Squad</button>
                <button onClick={() => setIsJoining(true)}>Join Squad</button>

                {isCreating && (
                    <form onSubmit={handleCreateSquad}>
                        <input
                            type="text"
                            value={squadName}
                            onChange={(e) => setSquadName(e.target.value)}
                            placeholder="Squad Name"
                            required
                        />
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Description"
                        />
                        <input
                            type="file"
                            onChange={(e) => setLogo(e.target.files[0])}
                        />
                        <button type="submit">Create Squad</button>
                    </form>
                )}

                {isJoining && (
                    <div>
                        <h3>Search Squads</h3>
                        <form onSubmit={handleSearch}>
                            <input
                                type="text"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                placeholder="Search Squads"
                                required
                            />
                            <button type="submit">Search</button>
                        </form>
                        <ul>
                            {squads.map(squad => (
                                <li key={squad.id}>
                                    {squad.name}
                                    <button onClick={() => handleApply(squad.id)}>Apply</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {message && <p>{message}</p>}
            </div>
        );
    }

    return (
        <div>
            <h2>{squad.name}</h2>
            <p>{squad.description}</p>
            {squad.logo && <img src={`http://localhost:3001/uploads/${squad.logo}`} alt="Squad Logo" />}
            <h3>Members</h3>
            <ul>
                {squad.members.map(member => (
                    <li key={member.id}>
                        {member.username}
                        {member.id !== squad.leader_id && (
                            <button onClick={() => handleKick(member.id)}>Kick</button>
                        )}
                    </li>
                ))}
            </ul>
            <h3>Invite Members</h3>
            <form onSubmit={handleInvite}>
                <input
                    type="text"
                    value={usernameOrEmail}
                    onChange={(e) => setUsernameOrEmail(e.target.value)}
                    placeholder="Username or Email"
                    required
                />
                <button type="submit">Invite</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default Squad;

import React, { useEffect, useState } from 'react';
import { Bracket, BracketGame } from 'react-tournament-bracket';
import {Box, Text, VStack, Button, useToast, Select} from '@chakra-ui/react';
import { useParams } from "react-router-dom";

function UpdateBrackets() {
    const [matches, setMatches] = useState([]);
    const [teams, setTeams] = useState({});
    const [winnerIds, setWinnerIds] = useState({});
    const toast = useToast();
    const { id } = useParams();

    // Fetch brackets and teams based on the tournament ID from URL params
    useEffect(() => {
        const fetchBracketsAndTeams = async () => {
            try {
                const response = await fetch(`https://api.senc.in/api/tournaments/${id}/brackets`);
                const brackets = await response.json();

                const teamResponse = await fetch(`https://api.senc.in/api/tournaments/${id}/teams`);
                const teams = await teamResponse.json();

                // Map team IDs to names
                const teamMap = teams.reduce((acc, team) => {
                    acc[team.id] = team.name;
                    return acc;
                }, {});

                setTeams(teamMap);

                // Convert the API response to the format required by the bracket component
                const formattedMatches = brackets.map(match => ({
                    id: match.matchNumber,
                    name: `Match ${match.matchNumber}`,
                    round: match.round,
                    scheduled: false,
                    sides: {
                        home: {
                            team: { id: match.team1Id, name: teamMap[match.team1Id] || `Team ${match.team1Id}` },
                            score: { score: match.team1Score || 0 },
                        },
                        visitor: {
                            team: { id: match.team2Id, name: teamMap[match.team2Id] || `Team ${match.team2Id}` },
                            score: { score: match.team2Score || 0 },
                        },
                    },
                }));

                setMatches(formattedMatches);
            } catch (error) {
                console.error('Error fetching brackets or teams:', error);
            }
        };

        fetchBracketsAndTeams();
    }, [id]);

    const handleWinnerChange = (matchId, winnerId, round) => {
        setWinnerIds(prev => ({ ...prev, [matchId]: { winnerId, round } }));
    };

    const updateWinner = async (matchId) => {
        const winnerData = winnerIds[matchId];
        if (!winnerData || !winnerData.winnerId) {
            toast({ title: 'Please select a winner.', status: 'error', duration: 3000 });
            return;
        }

        try {
            const response = await fetch('https://api.senc.in//api/brackets/updates', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    matchId,
                    winnerId: winnerData.winnerId,
                    round: winnerData.round,
                    tournamentId: id
                }),
            });

            const result = await response.json();
            if (response.ok) {
                toast({ title: result.message, status: 'success', duration: 3000 });
                await fetchBracketsAndTeams(); // Refresh the bracket data to reflect the changes
            } else {
                toast({ title: result.message, status: 'error', duration: 3000 });
            }
        } catch (error) {
            console.error('Error updating bracket:', error);
            toast({ title: 'Error updating bracket.', status: 'error', duration: 3000 });
        }
    };

    return (
        <Box className="tournament-bracket-container" p={4} bg="gray.800" color="white" minH="100vh" pt="6rem">
            {matches.length > 0 ? (
                <VStack className="bracket-wrapper" spacing={4} align="start">
                    {matches.map((match) => (
                        <Box key={match.id} className="bracket-item" p={4} borderWidth="1px" borderRadius="lg" bg="gray.700">
                            <Bracket
                                game={match}
                                GameComponent={BracketGame}
                            />
                            <Select
                                placeholder="Select Winner"
                                mt={2}
                                onChange={(e) => handleWinnerChange(match.id, e.target.value, match.round)}
                                value={winnerIds[match.id]?.winnerId || ''}
                                bg="gray.600"
                                color="white"
                            >
                                <option value={match.sides.home.team.id}>{match.sides.home.team.name}</option>
                                <option value={match.sides.visitor.team.id}>{match.sides.visitor.team.name}</option>
                            </Select>
                            <Button
                                onClick={() => updateWinner(match.id)}
                                mt={2}
                                colorScheme="teal"
                                size="sm"
                            >
                                Update Winner
                            </Button>
                        </Box>
                    ))}
                </VStack>
            ) : (
                <Text className="no-brackets-message">No brackets available</Text>
            )}
        </Box>
    );
}

export default UpdateBrackets;

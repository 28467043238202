// src/pages/Changelog.js
import React, { useState, useEffect } from 'react';
import { marked } from 'marked';
import axios from 'axios';
import changelogContent from './CHANGELOG.md';

const Changelog = () => {
    const [selectedOption, setSelectedOption] = useState('website');
    const [changelogContent, setChangelogContent] = useState('');

    useEffect(() => {
        axios.get("../../CHANGELOG.md")
            .then(response => {
                setChangelogContent(response.data);
            })
            .catch(error => {
                console.error('Error fetching changelog:', error);
            });
    }, []);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    return (
        <div style={pageStyle}>
            <h2>Changelog</h2>
            <div style={buttonContainerStyle}>
                <button onClick={() => handleOptionChange('website')} style={buttonStyle}>Website</button>
                <button onClick={() => handleOptionChange('app')} style={buttonStyle}>App</button>
            </div>
            <div dangerouslySetInnerHTML={{ __html: marked(changelogContent) }} />
        </div>
    );
};

const pageStyle = {
    padding: '2rem',
};

const buttonContainerStyle = {
    display: 'flex',
    gap: '1rem',
    marginBottom: '1rem',
};

const buttonStyle = {
    padding: '0.5rem 1rem',
    cursor: 'pointer',
};

export default Changelog;

import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box, Heading, Button, Flex, Link, Text, VStack, HStack, Image, IconButton, useColorMode, Tooltip
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { FaInfoCircle, FaArrowRight } from 'react-icons/fa';
import { BsFillStarFill } from 'react-icons/bs';
import logo from '../logos/senc.png';

const MotionBox = motion(Box);

const Home = () => {
    const [events, setEvents] = useState([]);
    const { colorMode, toggleColorMode } = useColorMode();

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get('https://api.senc.in/api/tournaments');
                setEvents(response.data);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };
        fetchEvents();
    }, []);

    return (
        <Box minH="100vh" bgGradient="linear(to-r, #1c1c1e, #2c3e50)" color="white" overflow="hidden">
            {/* Top Navbar */}
            <Flex justifyContent="space-between" alignItems="center" p="1.5rem">
                <Image src={logo} alt="SENC eSports Logo" boxSize="60px" />
                <Tooltip label={`Switch to ${colorMode === 'light' ? 'dark' : 'light'} mode`} aria-label="Toggle color mode">
                    <IconButton
                        icon={colorMode === 'light' ? <FaArrowRight /> : <FaInfoCircle />}
                        onClick={toggleColorMode}
                        variant="ghost"
                        colorScheme="orange"
                        fontSize="2xl"
                    />
                </Tooltip>
            </Flex>

            {/* Animated Welcome Section */}
            <MotionBox
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
                py="4rem"
            >
                <Image src={logo} alt="SENC Logo" boxSize="120px" mb="2rem" />
                <Heading as="h1" size="2xl" color="#ff8c00">
                    Welcome to SENC eSports
                </Heading>
                <Text fontSize="lg" mt="1rem" color="gray.300">
                    Join us for a world-class competitive gaming experience.
                </Text>
            </MotionBox>

            {/* Feature Section */}
            <Box mt="3rem" textAlign="center">
                <Heading as="h2" size="lg" mb="1rem" color="orange.300">
                    Our Features
                </Heading>
                <Flex wrap="wrap" justifyContent="center" gap="2rem">
                    {['Competitive Tournaments', 'Exciting Prizes', 'Global Community', 'Leaderboards & Rankings'].map((feature, index) => (
                        <MotionBox
                            key={index}
                            whileHover={{ scale: 1.05 }}
                            p="1.5rem"
                            bg="rgba(255, 255, 255, 0.1)"
                            borderRadius="lg"
                            width={{ base: '100%', md: '45%', lg: '22%' }}
                            textAlign="center"
                            color="white"
                        >
                            <BsFillStarFill color="#ff8c00" size="2rem" />
                            <Text fontSize="xl" fontWeight="bold" mt="1rem">{feature}</Text>
                        </MotionBox>
                    ))}
                </Flex>
            </Box>

            {/* Ongoing Events Section */}
            <Box mt="4rem" p="1rem" bg="rgba(255, 255, 255, 0.05)" borderRadius="lg">
                <Heading as="h2" size="lg" mb="2rem" textAlign="center" color="orange.300">
                    Ongoing Events
                </Heading>
                <VStack spacing="1.5rem" align="start" mx="auto" maxW="800px">
                    {events.length === 0 ? (
                        <Text fontSize="lg" color="gray.400">No events available at the moment.</Text>
                    ) : (
                        events.map((event) => (
                            <HStack key={event.id} w="100%" p="1rem" bg="rgba(255, 255, 255, 0.1)" borderRadius="lg">
                                <Image src={event.logo ? `https://api.senc.in/uploads/${event.logo}` : '/path/to/default.jpg'} alt={event.name} boxSize="80px" borderRadius="8px" />
                                <Box>
                                    <Text fontSize="lg" fontWeight="bold" color="orange.200">{event.name}</Text>
                                    <Text fontSize="sm" color="gray.400">Date: {new Date(event.registrationStart).toLocaleDateString()}</Text>
                                </Box>
                                <Link as={RouterLink} to={`/events/${event.id}`} ml="auto">
                                    <Button colorScheme="orange" size="sm" rightIcon={<FaArrowRight />}>View</Button>
                                </Link>
                            </HStack>
                        ))
                    )}
                </VStack>
            </Box>

            {/* Call to Action */}
            <MotionBox
                mt="4rem"
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1.2 }}
            >
                <Text fontSize="xl" mb="2rem" color="gray.300">
                    Ready to make your mark? Join SENC eSports today!
                </Text>
                <Flex gap="1rem">
                    <Link as={RouterLink} to="/login">
                        <Button colorScheme="orange" size="lg" fontSize="xl" px="2rem">
                            Login
                        </Button>
                    </Link>
                    <Link as={RouterLink} to="/register">
                        <Button variant="outline" colorScheme="orange" size="lg" fontSize="xl" px="2rem">
                            Register
                        </Button>
                    </Link>
                </Flex>
            </MotionBox>
        </Box>
    );
};

export default Home;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    List,
    ListItem,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    VStack,
    HStack,
    Image,
    Card,
    CardBody,
    Text,
} from '@chakra-ui/react';

const ManageTournaments = () => {
    const [tournaments, setTournaments] = useState([]);
    const [newTournament, setNewTournament] = useState({
        name: '',
        game: '',
        registrationStart: '',
        registrationEnd: '',
        prizePool: '',
        maxSlots: '',
        logo: null,
        description: ''
    });
    const [isEditing, setIsEditing] = useState(false);
    const [editTournament, setEditTournament] = useState({
        id: '',
        name: '',
        game: '',
        registrationStart: '',
        registrationEnd: '',
        prizePool: '',
        maxSlots: '',
        logo: null,
        description: ''
    });
    const [isViewingTeams, setIsViewingTeams] = useState(false);
    const [teams, setTeams] = useState([]);
    const [bracketType, setBracketType] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        axios.get('https://api.senc.in/api/tournaments')
            .then((response) => setTournaments(response.data))
            .catch((error) => console.error('Error fetching tournaments:', error));
    }, []);

    const handleEditClick = (tournament) => {
        setEditTournament(tournament);
        setIsEditing(true);
    };

    const handleViewTeamsClick = async (tournamentId) => {
        try {
            const response = await axios.get(`https://api.senc.in/api/tournaments/${tournamentId}/teams`);
            setTeams(response.data);
            setIsViewingTeams(true);
            onOpen();
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewTournament(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        setNewTournament(prevState => ({
            ...prevState,
            logo: e.target.files[0]
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const key in newTournament) {
            formData.append(key, newTournament[key]);
        }

        axios.post('https://api.senc.in/api/tournaments/add', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                setTournaments([...tournaments, response.data]);
                setNewTournament({
                    name: '',
                    game: '',
                    registrationStart: '',
                    registrationEnd: '',
                    prizePool: '',
                    maxSlots: '',
                    logo: null,
                    description: ''
                });
            })
            .catch((error) => console.error('Error creating tournament:', error));
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditTournament(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleEditFileChange = (e) => {
        setEditTournament(prevState => ({
            ...prevState,
            logo: e.target.files[0]
        }));
    };

    const handleEditSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const key in editTournament) {
            formData.append(key, editTournament[key]);
        }

        axios.put(`https://api.senc.in/api/tournaments/update/${editTournament.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                setTournaments(prevTournaments => prevTournaments.map(tournament =>
                    tournament.id === response.data.id ? response.data : tournament
                ));
                setIsEditing(false);
                setEditTournament({
                    id: '',
                    name: '',
                    game: '',
                    registrationStart: '',
                    registrationEnd: '',
                    prizePool: '',
                    maxSlots: '',
                    logo: null,
                    description: ''
                });
            })
            .catch((error) => console.error('Error updating tournament:', error));
    };

    const exportToExcel = () => {
        const data = teams.map(team => ({
            TeamName: team.name,
            LeaderID: team.leader_id,
            Players: team.players.map(player => `${player.name} (${player.in_game_name})`).join(', '),
            Logo: team.logo,
            UserID: team.user_id,
            ServerID: team.server_id
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Teams');
        XLSX.writeFile(workbook, 'tournament_teams.xlsx');
    };

    const exportToXML = () => {
        const data = teams.map(team => ({
            TeamName: team.name,
            LeaderID: team.leader_id,
            Players: team.players.map(player => `${player.name} (${player.in_game_name})`).join(', '),
            Logo: team.logo,
            UserID: team.user_id,
            ServerID: team.server_id
        }));

        const xmlData = `<Teams>${data.map(team => 
            `<Team>
                <TeamName>${team.TeamName}</TeamName>
                <LeaderID>${team.LeaderID}</LeaderID>
                <Players>${team.Players}</Players>
                <Logo>${team.Logo}</Logo>
                <UserID>${team.UserID}</UserID>
                <ServerID>${team.ServerID}</ServerID>
            </Team>`).join('')}
        </Teams>`;

        const blob = new Blob([xmlData], { type: 'application/xml' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'tournament_teams.xml';
        link.click();
    };

    const handleGenerateBrackets = async (tournamentId) => {
        const bracketType = prompt('Enter bracket type (single, double, best of 3, best of 5, best of 7):');
        try {
            await axios.post(`https://api.senc.in/api/tournaments/${tournamentId}/generate-brackets`, { bracketType });
            alert('Brackets generated successfully');
        } catch (error) {
            console.error('Error generating brackets:', error);
            alert('Error generating brackets');
        }
    };

    const handleUpdateBracket = (bracketId, winnerId) => {
        axios.post('http://localhost:3001/api/brackets/update', { bracketId, winnerId })
            .then(response => {
                // Update the brackets state
                setBrackets(prevBrackets => prevBrackets.map(bracket =>
                    bracket.id === bracketId ? { ...bracket, winnerId } : bracket
                ));
            })
            .catch(error => console.error('Error updating bracket:', error));
    };

    return (
        <Box p={4} pt={16} bg="gray.900" color="white"> {/* Added pt={16} for top padding and dark background */}
            <Heading mb={4}>Manage Tournaments</Heading>
            <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                    <FormControl isRequired>
                        <FormLabel>Tournament Name</FormLabel>
                        <Input
                            type="text"
                            name="name"
                            value={newTournament.name}
                            onChange={handleChange}
                            placeholder="Tournament Name"
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Game</FormLabel>
                        <Input
                            type="text"
                            name="game"
                            value={newTournament.game}
                            onChange={handleChange}
                            placeholder="Game"
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Registration Start</FormLabel>
                        <Input
                            type="datetime-local"
                            name="registrationStart"
                            value={newTournament.registrationStart}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Registration End</FormLabel>
                        <Input
                            type="datetime-local"
                            name="registrationEnd"
                            value={newTournament.registrationEnd}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Prize Pool</FormLabel>
                        <Input
                            type="number"
                            name="prizePool"
                            value={newTournament.prizePool}
                            onChange={handleChange}
                            placeholder="Prize Pool"
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Max Slots</FormLabel>
                        <Input
                            type="number"
                            name="maxSlots"
                            value={newTournament.maxSlots}
                            onChange={handleChange}
                            placeholder="Max Slots"
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Logo</FormLabel>
                        <Input
                            type="file"
                            name="logo"
                            onChange={handleFileChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Description</FormLabel>
                        <Textarea
                            name="description"
                            value={newTournament.description}
                            onChange={handleChange}
                            placeholder="Description"
                        />
                    </FormControl>
                    <Button type="submit" colorScheme="teal">Create Tournament</Button>
                </VStack>
            </form>
            <List spacing={3} mt={4}>
                {tournaments.map(tournament => (
                    <ListItem key={tournament.id}>
                        <HStack spacing={4}>
                            <Box>
                                {tournament.name} - {tournament.registrationStart} to {tournament.registrationEnd}
                            </Box>
                            <Button size="sm" onClick={() => handleEditClick(tournament)}>Edit</Button>
                            <Button size="sm" onClick={() => handleViewTeamsClick(tournament.id)}>View Teams</Button>
                            <Button size="sm" onClick={() => handleGenerateBrackets(tournament.id)}>Generate Brackets</Button>
                        </HStack>
                    </ListItem>
                ))}
            </List>
            {isEditing && (
                <form onSubmit={handleEditSubmit}>
                    <VStack spacing={4} mt={4}>
                        <FormControl isRequired>
                            <FormLabel>Tournament Name</FormLabel>
                            <Input
                                type="text"
                                name="name"
                                value={editTournament.name}
                                onChange={handleEditChange}
                                placeholder="Tournament Name"
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Game</FormLabel>
                            <Input
                                type="text"
                                name="game"
                                value={editTournament.game}
                                onChange={handleEditChange}
                                placeholder="Game"
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Registration Start</FormLabel>
                            <Input
                                type="datetime-local"
                                name="registrationStart"
                                value={editTournament.registrationStart}
                                onChange={handleEditChange}
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Registration End</FormLabel>
                            <Input
                                type="datetime-local"
                                name="registrationEnd"
                                value={editTournament.registrationEnd}
                                onChange={handleEditChange}
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Prize Pool</FormLabel>
                            <Input
                                type="number"
                                name="prizePool"
                                value={editTournament.prizePool}
                                onChange={handleEditChange}
                                placeholder="Prize Pool"
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Max Slots</FormLabel>
                            <Input
                                type="number"
                                name="maxSlots"
                                value={editTournament.maxSlots}
                                onChange={handleEditChange}
                                placeholder="Max Slots"
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Logo</FormLabel>
                            <Input
                                type="file"
                                name="logo"
                                onChange={handleEditFileChange}
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                name="description"
                                value={editTournament.description}
                                onChange={handleEditChange}
                                placeholder="Description"
                            />
                        </FormControl>
                        <Button type="submit" colorScheme="teal">Update Tournament</Button>
                    </VStack>
                </form>
            )}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Registered Teams</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4}>
                            {teams.map(team => (
                                <Card key={team.id} bg="gray.700" color="white" width="100%">
                                    <CardBody>
                                        <HStack spacing={4}>
                                            <Image boxSize="50px" src={team.logo} alt={`${team.name} logo`} />
                                            <VStack align="start">
                                                <Text fontWeight="bold">{team.name}</Text>
                                                <Text>Leader ID: {team.leader_id}</Text>
                                                <Text>User ID: {team.user_id}</Text>
                                                <Text>Server ID: {team.server_id}</Text>
                                                <List spacing={2} mt={2}>
                                                    {team.players.map(player => (
                                                        <ListItem key={player.id}>
                                                            {player.name} ({player.in_game_name})
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </VStack>
                                        </HStack>
                                    </CardBody>
                                </Card>
                            ))}
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={exportToExcel}>Export to Excel</Button>
                        <Button colorScheme="blue" mr={3} onClick={exportToXML}>Export to XML</Button>
                        <Button variant="ghost" onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default ManageTournaments;

import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, FormControl, FormLabel, Input, Textarea, Checkbox, VStack } from '@chakra-ui/react';

const CreateTournament = () => {
    const [newTournament, setNewTournament] = useState({
        name: '',
        game: '',
        registrationStart: '',
        registrationEnd: '',
        prizePool: '',
        maxSlots: '',
        logo: null,
        description: '',
        enableWhatsApp: false,
        whatsappLink: '',
        enableYouTube: false,
        youtubeLink: '',
        enableDiscord: false,
        discordLink: ''
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewTournament(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleFileChange = (e) => {
        setNewTournament(prevState => ({
            ...prevState,
            logo: e.target.files[0]
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const key in newTournament) {
            formData.append(key, newTournament[key]);
        }

        axios.post('https://api.senc.in/api/tournaments/add', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                // Handle success
                console.log('Tournament created successfully:', response.data);
            })
            .catch(error => {
                // Handle error
                console.error('Error creating tournament:', error);
            });
    };

    return (
        <Box p={4} pt={32}>
            <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                    <FormControl isRequired>
                        <FormLabel>Tournament Name</FormLabel>
                        <Input
                            type="text"
                            name="name"
                            value={newTournament.name}
                            onChange={handleChange}
                            placeholder="Tournament Name"
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Game</FormLabel>
                        <Input
                            type="text"
                            name="game"
                            value={newTournament.game}
                            onChange={handleChange}
                            placeholder="Game"
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Registration Start</FormLabel>
                        <Input
                            type="datetime-local"
                            name="registrationStart"
                            value={newTournament.registrationStart}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Registration End</FormLabel>
                        <Input
                            type="datetime-local"
                            name="registrationEnd"
                            value={newTournament.registrationEnd}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Prize Pool</FormLabel>
                        <Input
                            type="number"
                            name="prizePool"
                            value={newTournament.prizePool}
                            onChange={handleChange}
                            placeholder="Prize Pool"
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Max Slots</FormLabel>
                        <Input
                            type="number"
                            name="maxSlots"
                            value={newTournament.maxSlots}
                            onChange={handleChange}
                            placeholder="Max Slots"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Logo</FormLabel>
                        <Input
                            type="file"
                            name="logo"
                            onChange={handleFileChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Description</FormLabel>
                        <Textarea
                            name="description"
                            value={newTournament.description}
                            onChange={handleChange}
                            placeholder="Description"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Enable WhatsApp Group</FormLabel>
                        <Checkbox
                            isChecked={newTournament.enableWhatsApp}
                            onChange={handleChange}
                            name="enableWhatsApp"
                        >
                            Enable
                        </Checkbox>
                        {newTournament.enableWhatsApp && (
                            <Input
                                type="url"
                                name="whatsappLink"
                                value={newTournament.whatsappLink}
                                onChange={handleChange}
                                placeholder="WhatsApp Group Link"
                            />
                        )}
                    </FormControl>
                    <FormControl>
                        <FormLabel>Enable YouTube Channel</FormLabel>
                        <Checkbox
                            isChecked={newTournament.enableYouTube}
                            onChange={handleChange}
                            name="enableYouTube"
                        >
                            Enable
                        </Checkbox>
                        {newTournament.enableYouTube && (
                            <Input
                                type="url"
                                name="youtubeLink"
                                value={newTournament.youtubeLink}
                                onChange={handleChange}
                                placeholder="YouTube Channel Link"
                            />
                        )}
                    </FormControl>
                    <FormControl>
                        <FormLabel>Enable Discord</FormLabel>
                        <Checkbox
                            isChecked={newTournament.enableDiscord}
                            onChange={handleChange}
                            name="enableDiscord"
                        >
                            Enable
                        </Checkbox>
                        {newTournament.enableDiscord && (
                            <Input
                                type="url"
                                name="discordLink"
                                value={newTournament.discordLink}
                                onChange={handleChange}
                                placeholder="Discord Link"
                            />
                        )}
                    </FormControl>
                    <Button type="submit" colorScheme="teal">Create Tournament</Button>
                </VStack>
            </form>
        </Box>
    );
};

export default CreateTournament;

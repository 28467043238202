import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Heading, Text, VStack, FormControl, FormLabel, Input, Textarea, Image, useToast, HStack } from '@chakra-ui/react';
import axios from 'axios';

const ManageTournament = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const [tournament, setTournament] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        fetchTournament();
    }, [id]);

    const fetchTournament = async () => {
        try {
            const response = await axios.get(`https://api.senc.in/api/tournaments/${id}`);
            setTournament(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching tournament:', error);
            toast({
                title: "Error",
                description: "Failed to fetch tournament details.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTournament({ ...tournament, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://api.senc.in/api/tournaments/${id}`, tournament);
            toast({
                title: "Success",
                description: "Tournament updated successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating tournament:', error);
            toast({
                title: "Error",
                description: "Failed to update tournament.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this tournament?")) {
            try {
                await axios.delete(`https://api.senc.in/api/tournaments/${id}`);
                toast({
                    title: "Success",
                    description: "Tournament deleted successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                navigate('/dashboard');
            } catch (error) {
                console.error('Error deleting tournament:', error);
                toast({
                    title: "Error",
                    description: "Failed to delete tournament.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    const handleGenerateBrackets = async () => {
        try {
            await axios.post(`https://api.senc.in/api/tournaments/${id}/generate-brackets`);
            toast({
                title: "Success",
                description: "Brackets generated successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error generating brackets:', error);
            toast({
                title: "Error",
                description: "Failed to generate brackets.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleUpdateBrackets = () => {
        navigate(`/update-brackets-user/${id}`);
    };

    const handleViewTeams = () => {
        navigate(`/view-teams/${id}`);
    };

    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    return (
        <Box p={6}>
            <Heading mb={6}>Manage Tournament: {tournament.name}</Heading>
            <VStack spacing={4} align="stretch">
                {tournament.logo && (
                    <Image src={`https://api.senc.in/uploads/${tournament.logo}`} alt={`${tournament.name} logo`} maxWidth="200px" />
                )}
                <form onSubmit={handleSubmit}>
                    <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input name="name" value={tournament.name} onChange={handleInputChange} readOnly={!isEditing} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Game</FormLabel>
                        <Input name="game" value={tournament.game} onChange={handleInputChange} readOnly={!isEditing} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Registration Start</FormLabel>
                        <Input name="registrationStart" type="date" value={tournament.registrationStart.split('T')[0]} onChange={handleInputChange} readOnly={!isEditing} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Registration End</FormLabel>
                        <Input name="registrationEnd" type="date" value={tournament.registrationEnd.split('T')[0]} onChange={handleInputChange} readOnly={!isEditing} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Prize Pool</FormLabel>
                        <Input name="prizePool" type="number" value={tournament.prizePool} onChange={handleInputChange} readOnly={!isEditing} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Max Slots</FormLabel>
                        <Input name="maxSlots" type="number" value={tournament.maxSlots} onChange={handleInputChange} readOnly={!isEditing} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Description</FormLabel>
                        <Textarea name="description" value={tournament.description} onChange={handleInputChange} readOnly={!isEditing} />
                    </FormControl>
                    {isEditing ? (
                        <Button mt={4} colorScheme="blue" type="submit">Save Changes</Button>
                    ) : (
                        <Button mt={4} colorScheme="blue" onClick={() => setIsEditing(true)}>Edit</Button>
                    )}
                </form>
                <HStack spacing={4} mt={4}>
                    <Button colorScheme="green" onClick={handleGenerateBrackets}>Generate Brackets</Button>
                    <Button colorScheme="green" onClick={handleViewTeams}>View Teams</Button>
                    <Button colorScheme="purple" onClick={handleUpdateBrackets}>Update Brackets</Button>
                    <Button colorScheme="red" onClick={handleDelete}>Delete Tournament</Button>
                </HStack>
            </VStack>
        </Box>
    );
};

export default ManageTournament;

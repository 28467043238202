// theme.js
import { extendTheme } from '@chakra-ui/react';

const customTheme = extendTheme({
    colors: {
        gray: {
            700: '#4A5568',
            900: '#1A202C',
        },
    },
});

export default customTheme;

import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer style={footerStyle}>
            <div style={linkContainerStyle}>
                <Link to="/privacy-policy" style={linkStyle}>Privacy Policy</Link>
                <Link to="/contact-us" style={linkStyle}>Contact Us</Link>
                <Link to="/terms-and-conditions" style={linkStyle}>Terms & Conditions</Link>
                <Link to="/changelog" style={linkStyle}>Changelog</Link>
            </div>
        </footer>
    );
};

const footerStyle = {
    backgroundColor: '#333',
    color: '#fff',
    padding: '1rem',
    position: 'relative',
    bottom: '0',
    width: '100%',
    textAlign: 'center',
    boxSizing: 'border-box',
};

// Responsive styles for link container
const linkContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',  // Reduce gap for better handling on smaller screens
    flexWrap: 'wrap',
    maxWidth: '100%',
    padding: '0 1rem',
    boxSizing: 'border-box',
};

// Styling for links
const linkStyle = {
    color: '#fff',
    textDecoration: 'none',
    margin: '0.5rem 0',
    wordBreak: 'break-word',  // Ensure long words break if necessary
};

// Media query for mobile devices
const mediaQueryStyles = `
    @media (max-width: 600px) {
        .linkContainer {
            flexDirection: 'column',  // Stack links vertically on small screens
        }
    }
`;

export default Footer;
